
import { defineComponent, Ref, ref } from 'vue';
import { RequestData, ListHeaderItem, listPagination } from '@/components/common/list';
import router, { distributorSubRouter, distributorBaseRouter } from '@/router';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import autopDialog from '@/components/view/common/dialog/autop';

const searchKeyList = [{
    label: WordList.TabelPersonDeviceInHtmlMAC,
    name: 'MAC'
}, {
    label: WordList.TabelPersonDeviceInHtmlLocation,
    name: 'Location'
}, {
    label: WordList.TabelUserInHtmlRoleInstall,
    name: 'Install'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    name: 'Sip'
}];
const headers: Array<ListHeaderItem> = [{
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation
}, {
    name: 'Owner',
    label: WordList.TabelPersonDeviceInHtmlOwner
}, {
    name: 'Install',
    label: WordList.TabelUserInHtmlRoleInstall
}, {
    name: 'DeviceType',
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
    type: 'customize'
}, {
    name: 'SipAccount',
    label: WordList.MulListUpdateListTanleAccount
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'BindingTime',
    label: WordList.PersonaldeviceListTanleBindingTime
}];
export default defineComponent({
    components: {
        listPagination,
        deviceStatus,
        deviceIcon,
        autopDialog
    },
    setup() {
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'v3/web/single/device/getList',
            param: {
                searchKey: 'MAC',
                searchValue: ''
            }
        });

        const goToDeviceInfo = (item: object) => {
            localStorage.setItem('detail', JSON.stringify(item));
            router.push(`/${distributorBaseRouter}/${distributorSubRouter.personalDeviceDetail}`);
        };

        const isShowAutopDialog = ref(false);
        const autoP = CONFIG_ENV.isOpenAutoP;
        return {
            listRequestData,
            updateToList,
            searchKeyList,
            headers,
            goToDeviceInfo,
            isShowAutopDialog,
            autoP
        };
    }
});
